import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import './style.css';
import FirstAccountCreationStep from './pages/FirstAccountCreationStep';
import SecondAccountCreationStep from './pages/SecondAccountCreationStep';
import ThirdAccountCreationStep from './pages/ThirdAccountCreationStep';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Success from './pages/Success';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnAndRefundPolicy from './pages/ReturnAndRefundPolicy';
import ResetPassword from './pages/ResetPassword';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/first_account_creation_step",
    element: <FirstAccountCreationStep />,
  },
  {
    path: "/second_account_creation_step/:stepId",
    element: <SecondAccountCreationStep />,
  },
  {
    path: "/third_account_creation_step/",
    element: <ThirdAccountCreationStep />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/terms_of_service",
    element: <TermsOfService />,
  },
  {
    path: "/privacy_policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/return_and_refund_privacy",
    element: <ReturnAndRefundPolicy />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
