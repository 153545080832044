
import freeSvg from "../images/free.svg";
import fanSvg from "../images/fan.svg";
import memberSvg from "../images/member.svg";
import { Link } from "react-router-dom";

export default function SubscriptionItem(props) {
    let description;
    let amount;
    let logo;
    let link;
    if (props.toGo === "1") {
        link = "/first_account_creation_step";
    }
    else {
        if (props.offerType === 'free') {
            link = "/second_account_creation_step/1";
        }
        else if (props.offerType === 'fan') {
            link = "/second_account_creation_step/2";
        }
        else {
            link = "/second_account_creation_step/3";
        }

    }
    if (props.offerType === 'free') {
        description = <p>Access to free content < br ></br > Access to RM26 services</p>;
        amount = "FREE";
        logo = <img src={freeSvg} alt="Riyad Mahrez" />
    } else if (props.offerType === 'fan') {
        description = <p>Access to Free Content < br ></br > Access to Premium Content < br ></br > Access to RM26 Services < br ></br > Entry into a drawing to win a signed jersey < br ></br > 12% Discount on Paid Services</p>;
        amount = "USD 3.00 / MONTH";
        logo = <img src={fanSvg} alt="Riyad Mahrez" />

    } else if (props.offerType === 'member') {
        description = <p>Receipt of an RM26 member card< br ></br > Access to free content < br ></br > Access to premium content < br ></br > Access to RM26 services < br ></br > Exclusive invitation to a RM26 Live < br ></br > <span> Receipt of a signed Al Ahli jersey by Riyad Mahrez </span> < br ></br > 25% discount on paid services</p>;
        amount = "USD 239.99 / YEAR";
        logo = <img src={memberSvg} alt="Riyad Mahrez" />

    }



    return (


        <div className='items-subscriptions'>
            <div className="img">
                {logo}
            </div>
            <div className="desc">
                <h3>What's included:</h3>{description}
            </div>
            <div className='offer--amount'>{amount}</div>
            <Link className='register_link' to={link}><div className="account">Register</div></Link>


        </div>
    )
};
