import React, { useEffect } from 'react';

const NewsletterForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://pulse83294.activehosted.com/f/embed.php?id=3';
        script.type = 'text/javascript';
        script.charset = 'utf-8';

        const newsletterForm = document.getElementById('newsletter-form');
        if (newsletterForm) {
            newsletterForm.appendChild(script);
        }

        return () => {
            if (newsletterForm && newsletterForm.contains(script)) {
                newsletterForm.removeChild(script);
            }
        };
    }, []);

    return <div id="newsletter-form" />;
};

export default NewsletterForm;
