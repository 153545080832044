
import SubscriptionItem from '../components/SubscriptionItem';
import Header from '../components/hearder';
import Footer from '../components/footer';
import AccountCreationTitle from '../components/AccountCreationTitle';

const FirstAccountCreationStep = () => {
    return (
        <div className='first-step--container'>
            <Header />
            <div className='container'>
                <AccountCreationTitle step="first" />
                <div className='subscriptions'>
                    <SubscriptionItem offerType="free" toGo="2" />
                    <SubscriptionItem offerType="fan" toGo="2" />
                    <SubscriptionItem offerType="member" toGo="2" />
                </div>
            </div>
            <Footer />

        </div>
    );
}

export default FirstAccountCreationStep;