
import Header from '../components/hearder';
import Footer from '../components/footer';


const ResetPassword = () => {
    return (
        <div className='reset-password--container'>
            <Header />
            <div className='container'>
                <div class="container content-text content-center">
                    <h2>Reset password  </h2>
                    <p>Please open the link from your mobile phone with RM26 application already installed</p>

                </div>
            </div>
            <Footer />

        </div >
    );
}

export default ResetPassword;