import Header from '../components/hearder';
import Footer from '../components/footer';
import AccountCreationTitle from '../components/AccountCreationTitle';
import PaymentForm from '../components/PaymentForm';

export default function ThirdAccountCreationStep() {



    return (
        <div className='third-step--container'>

            <Header />
            <div className='container'>

                <AccountCreationTitle step="third" />
                <div className='subscriptions'>
                    <PaymentForm />


                </div>
            </div>
            <Footer />

        </div>
    );
}

